<!--
 * @Author: jiang
 * @Date: 2021-07-13 17:07:46
 * @Description:
-->
<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="formRules"
    label-position="top"
  >
    <el-form-item
      label="时间"
      prop="schedule_time"
    >
      <el-date-picker
        v-model="formData.schedule_time"
        value-format="yyyy-MM-dd"
        placeholder="请选择"
      ></el-date-picker>
    </el-form-item>

    <el-form-item
      label="内容"
      prop="content"
    >
      <el-input
        v-model="formData.content"
        type="textarea"
        :autosize="{minRows: 3}"
        placeholder="请输入"
      ></el-input>
    </el-form-item>

    <div class="g-center g-mt-20">
      <el-button
        :loading="loading"
        type="primary"
        @click="onSave"
        icon="el-icon-plus"
      >保存</el-button>
    </div>
  </el-form>
</template>

<script>
import { createSchedule, updateSchedule } from '@/api/manager'

export default {
  props: {
    data: Object,
    code: {
      type: String,
      default: 'user',
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        schedule_time: '',
        content: '',
        department_id: '',
      },
      formRules: {
        schedule_time: { required: true, message: '必填项', trigger: 'change' },
        content: { required: true, message: '必填项', trigger: 'blur' },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val) {
          this.formData = val
          this.$nextTick(() => {
            this.$refs.form.clearValidate()
          })
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.formData.type = this.code
          if (this.formData.id) {
            updateSchedule(this.formData.id, this.formData)
              .then(res => {
                this.$message.success('更新成功')
                this.$emit('success')
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            createSchedule(this.formData)
              .then(res => {
                this.$message.success('新建成功')
                this.$emit('success')
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
  },
}
</script>
